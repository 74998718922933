import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import AddCallPNG from "../../images/add_call.png";
import MailPNG from "../../images/mail.png";
import ClosePNG from "../../images/close.png";
import "../../css/contact-us.css";
// import { Link } from "react-router-dom";
class C9 extends Component {

  state = {
    contactPopupOpen: false
  }

  onContactUs = e => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({
      contactPopupOpen: !this.state.contactPopupOpen
    });
  }

  render() {
    return (
      <Container fluid className="c9 c-pad">
        {
          this.state.contactPopupOpen ?
            (
              <div className="cnt-popup">
                <div className={"cnt-popup-overlay"} onClick={this.onContactUs} />
                <div className={"cnt-popup-content"}>
                  <div className={"cnt-popup-header"}>
                    <p style={{ marginBottom: 0 }}>
                      <span className={"cnt-popup-title"}>
                        Contact us
                      </span><br />
                      <span className={"cnt-popup-subtitle"}>
                        We'd love to hear from you! Whether you have a question, feedback or just want to say hello, we're here to help
                      </span>
                    </p>
                    <img src={ClosePNG} className={"cnt-popup-close"} onClick={this.onContactUs} />
                  </div>
                  <div className={"cnt-popup-body"}>
                    <p className={"cnt-popup-section"}>
                      <span className={"cnt-popup-section-heading"}>Junio Details</span><br />
                      <a href="mailto:hello@junio.in"><span className="cnt-popup-bold-text"><img src={MailPNG} style={{ width: "24px" }} /> hello@junio.in </span></a><br />
                      <a href="tel:+917967112114"><span className="cnt-popup-bold-text"><img src={AddCallPNG} style={{ width: "24px" }} /> +91-7967112114</span></a><br />
                    </p>
                    <p className={"cnt-popup-section"}>
                      <span className={"cnt-popup-section-heading"}>Queries related to Prepaid Payment Instrument (PPI) services:</span><br />
                      <a href="mailto:cards@transcorpint.com"><span className="cnt-popup-bold-text"><img src={MailPNG} style={{ width: "24px" }} /> cards@transcorpint.com </span></a><br />
                      <a href="tel:+917597182222"><span className="cnt-popup-bold-text"><img src={AddCallPNG} style={{ width: "24px" }} /> +91-7597182222</span></a>
                    </p>
                  </div>
                  <div className={"cnt-popup-footer"}>
                    <span style={{ borderTop: "1px solid #0F0F0F", opacity: 0.06, display: "inline-block", width: "100%" }}></span>
                    <p style={{ marginBottom: 0, fontWeight: 400, fontSize: "12px" }}>
                      In case of no resolution to your complaint is provided within 30 days or if the resolution is unsatisfactory, you may
                      escalate your concern on the RBI CMS portal: <a className={"cnt-popup-link"} href="https://cms.rbi.org.in/" target="_blank">https://cms.rbi.org.in/</a>
                    </p>
                  </div>
                </div>
              </div>) : null
        }
        <div
          className="row d-flex c9-d1"
          style={{ justifyContent: "space-between" }}
        >
          <Col className="d-flex col-12 col-lg-6">
            <a href="/terms" target="_blank">
              Terms of Services
            </a>
            <a href="/privacy" target="_blank" className="ms-5">
              Privacy Policy
            </a>
            <a href="#" className="ms-5" onClick={this.onContactUs}>
              Contact Us
            </a>
          </Col>
          <Col className="text-start text-lg-end mt-4 mt-md-0">
            <p className="firm_name">
              Copyright © 2024 Junio Payments Private Limited
            </p>
          </Col>
        </div>
        <Row className="justify-content-end">
          <Col className="col-md-auto px-2">
            <a target="_blank" href="https://www.facebook.com/JunioIndia">
              <FontAwesomeIcon icon={faFacebookF} size="lg" />
            </a>
          </Col>
          <Col className="col-md-auto px-2">
            <a target="_blank" href="https://www.instagram.com/junio.in/">
              <FontAwesomeIcon icon={faInstagram} size="lg" />
            </a>
          </Col>
          <Col className="col-md-auto px-2">
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UCZIRTZYoelCMox8Gvp6SOpw"
            >
              <FontAwesomeIcon icon={faYoutube} size="lg" />
            </a>
          </Col>
          <Col className="col-md-auto px-2">
            <a target="_blank" href="https://twitter.com/junio_in">
              <FontAwesomeIcon icon={faTwitter} size="lg" />
            </a>
          </Col>
          <Col className="col-md-auto px-2">
            <a
              target="_blank"
              href="https://www.linkedin.com/company/junio-smart-card/"
            >
              <FontAwesomeIcon icon={faLinkedin} size="lg" />
            </a>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default C9;
